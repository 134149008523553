/*
 * @Author: dingguowei
 * @Date: 2023-04-23 15:25:42
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-16 15:57:17
 */

import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/elementui.scss'
import './style/iconfonts/iconfont.css'
import axios from 'axios';
import App from './App.vue'
import router from './router'
import store from '@/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText }from '@fortawesome/vue-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import './style/index.scss'
import "uno.css";
library.add(fas, far, fab, faPowerOff)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)


Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false

// 防止localstorage修改
window.addEventListener('storage', function (e) {
  localStorage.setItem(e.key, e.oldValue)
})



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
