const state = {
  changeCurentSession: false,
  changeNewSessions:false,
  isNewMsg: false,
  isLoading: false,
  fakeId:null
  }
  
  const mutations = {
    CHANGE_CURENTSESSION: (state, data) => {
      state.changeCurentSession = data
    },
    CHANGE_SESSIONS: (state, data) => {
      state.changeNewSessions = data
    },
    CHANGE_ISNEWMSG: (state, data) => {
      state.isNewMsg = data
    },
    CHANGE_FAKEID: (state, data) => {
      state.fakeId = data
    },

}
const actions = {
  changesession: ({ commit }, val) => {

    commit('CHANGE_CURENTSESSION', val)
  },
  changenewsessions: ({ commit }, val) => {
    commit('CHANGE_SESSIONS', val)
  }
}
  
  export default {
    namespaced: true,
    state,
  mutations,
  actions
  }
  