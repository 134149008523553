/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:42:28
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-29 20:07:48
 */
import Vue from 'vue'
import Vuex from 'vuex'


import user from './modules/user'
import kind from './modules/kind'
import task from "./modules/taskmanger"

import createPersistedState from 'vuex-persistedstate'




Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    axiosCancel: [],
    tableStatus: false
  },
  mutations: {
    changeStatus(state, status) {
      state.tableStatus = status
    }
  },
  actions: {},
  modules: {
    user,
    kind,
    task
  },

  plugins: [
    // veux持久化配置
    createPersistedState({
      storage: window.localStorage,
      key: 'taskInfo',
      paths:['task','user']
    })
  ]
})
