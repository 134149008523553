/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:43:40
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-16 16:18:42
 */
const state = {
  userInfo: {
  },
  userCurTaskStatus: null,
  userid:null
  }
  
  const mutations = {
    CHANGE_userInfo: (state, data) => {
      state.userInfo = data
    },
    CHANGE_userCurTaskStatus: (state, data) => {

      state.userCurTaskStatus = data
    },
    CHANGE_userid: (state, data) => {

      state.userid = data
    },
  }
  
  export default {
    namespaced: true,
    state,
    mutations
  }
  