/*
 * @Author: dingguowei
 * @Date: 2023-06-06 14:34:53
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 19:55:00
 */
// 内部系统任务详情各参数
const state = {
  taskInfo: {
    missionId: null,//发布任务id
    receiverId: null,//提交任务id
    publisherAccount: null,
    publisherId: null,
    missionReceiveId: null,
  },
}

const mutations = {
  CHANGE_MISSIONID: (state, data) => {
    state.taskInfo.missionId = data
  },
  CHANGE_RECEIVERID: (state, data) => {
    state.taskInfo.receiverId = data
  },
  CHANGE_publisherAccount: (state, data) => {
    state.taskInfo.publisherAccount = data
  },
  CHANGE_publisherId: (state, data) => {
    state.taskInfo.publisherId = data
  },
  CHANGE_missionReceiveId: (state, data) => {
    state.taskInfo.missionReceiveId = data
  },

}

export default {
  namespaced: true,
  state,
  mutations
}